import React from 'react'
import { Typography, Card, Row, Col } from 'antd'
import MainLayout from '@layouts/MainLayout'
import { OutboundLink } from 'gatsby-plugin-gtag'

export default () => (
  <MainLayout>
    <div className='flex flex-col'>
      <Typography.Title className='text-primary-color font-headers font-bold'>
        Hi, and welcome.
      </Typography.Title>
      <Typography.Paragraph className='font-body text-xl'>
        I&apos;m <b>Ralph</b>, a <b>software engineer</b> for nearly a decade
        now, among other things:{' '}
        <OutboundLink href='https://porch.severian.dev'>
          a practicing Stoic
        </OutboundLink>
        , an avid science fiction reader, and an enthusiast for note-taking and
        storywriting.
      </Typography.Paragraph>
      <Typography.Paragraph className='font-body text-xl'>
        Here&apos;s a couple of relevant links: my{' '}
        <OutboundLink href='https://github.com/ralphstodomingo'>
          GitHub
        </OutboundLink>{' '}
        and{' '}
        <OutboundLink href='https://www.linkedin.com/in/ralph-sto-domingo-95a10bb8/'>
          Linkedin
        </OutboundLink>{' '}
        profiles, the latest{' '}
        <OutboundLink href={`${process.env.SITE_URL}/CV.pdf`}>
          copy of my CV in PDF format
        </OutboundLink>
        .
      </Typography.Paragraph>
      <Typography.Paragraph className='font-body text-xl'>
        If you have something for me, send me an email at{' '}
        <i>yo@[this domain]</i>.
      </Typography.Paragraph>
    </div>
    <div className='flex flex-col'>
      <Typography.Title
        level={3}
        className='text-primary-color font-headers font-bold'
      >
        Projects
      </Typography.Title>
      <Row type='flex' justify='center' gutter={20}>
        <Col xs={22} sm={22} md={12} lg={12} xl={12} xxl={12}>
          <Card
            className='mb-6'
            size='small'
            title={
              <span className='font-semibold text-xl text-current'>Porch</span>
            }
            extra={
              <span className='uppercase font-bold tracking-widest text-red-800'>
                Deployed
              </span>
            }
          >
            <p className='text-base '>
              Stoic inspiration in your browser, daily refreshed with scenic
              backgrounds from Unsplash. Also available as a Chrome extension.
            </p>
            <p className='text-base '>
              <OutboundLink
                href='https://porch.severian.dev'
                className='underline'
              >
                Check it out here.
              </OutboundLink>
            </p>
          </Card>
        </Col>
        <Col xs={22} sm={22} md={12} lg={12} xl={12} xxl={12}>
          <Card
            className='mb-6'
            size='small'
            title={
              <span className='font-semibold text-xl text-current'>
                Aldebaran
              </span>
            }
            extra={
              <span className='uppercase font-bold tracking-widest text-green-800'>
                Planning
              </span>
            }
          >
            <p className='text-base '>
              An evergreen content-taking and web publishing system, coming
              soon.
            </p>
          </Card>
        </Col>
      </Row>
    </div>
  </MainLayout>
)
